<template>
  <section class="content">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"
                  ><i class="fas fa-bookmark"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">TOTAL CLAIM</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.total_claim }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-warning elevation-1"
                  ><i class="fas fa-inbox"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">PENDING CLAIM</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.pending }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-success elevation-1"
                  ><i class="fas fa-check-double"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">APPROVED CLAIM</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.approve }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-danger elevation-1"
                  ><i class="fas fa-window-close"></i
                ></span>
                <div class="info-box-content">
                  <h5 class="info-box-text">NOT APPROVED CLAIM</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.not_approve }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
          </div>
          <div class="row form-horizontal">
            <div class="col-auto">
              <label class="control-label my-2 ml-2">
                <h6>Filter Pencarian</h6>
              </label>
            </div>
            <div class="col-auto">
              <select class="form-control" @change="dataType($event)">
                <option value="createdOn">Dibuat Antara</option>
                <!-- <option value="lastTm">Update Antara</option> -->
              </select>
            </div>
            <div class="col-auto">
              <div class="input-group mb-3">
                <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                <div class="input-group-append">
                  <div class="input-group-text" style="background-color: #fff">
                    <span><span class="fa fa-calendar"></span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <select id="claim_courier" class="form-control" @change="claimTypeCourier($event)">
                <option value="" disabled selected>Status Claim (By Courier)</option>
                <option value="pending_courier">Pending</option>
                <option value="approve_courier">Approved</option>
                <option value="not_approve_courier">Not Approved</option>
                <option value="banding">Banding</option>
                <option value="all_courier">Semua</option>
              </select>
            </div>
            <!-- <div class="col-auto">
              <select id="claim" class="form-control" @change="claimType($event)">
                <option value="" disabled selected>Status Claim</option>
                <option value="pending">Pending</option>
                <option value="approve">Approved</option>
                <option value="not_approve">Not Approved</option>
                <option value="all">Semua</option>
              </select>
            </div> -->
            <div class="col-auto text-right" v-if="true">
              <div class="btn-group" ref="toolbar">
                <button type="button" data-action="read" class="btn btn-default"><i
                    class="fa fa-sync-alt"></i></button>
                <button type="button" :disabled="downloading" v-on:click="downloadXlsx"
                  data-action="export-xlsx" class="btn btn-default" v-if="true"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
              </div>
            </div>
          </div>
          <div class="btn-area pt-3" ref="btnSelected" style="display:none;">
            <button type="button" class="ml-2 btn btn-success" @click="closeClaimReturn"
              :disabled="false">Ubah Status</button>
          </div>
          <div class="table-ticket pt-3">
            <table class="table table-hover" ref="tblticket" id="tblticket">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1"
                      @click="selectAll" />
                  </th>
                  <th>AWB</th>
                  <th>JUDUL</th>
                  <th>PIC</th>
                  <th>KATEGORI CLAIM</th>
                  <th>STATUS CLAIM (BY COURIER)</th>
                  <th>SHIPPING DATE</th>
                  <th>PENGAJUAN DATE</th>
                  <th>KOMENTAR</th>
                  <th>AGING/UMUR</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
            </table>
          </div>
          <FormClosingClaimCourier :show="showClosingClaimCourier" v-model="selectedItems" v-on:change="reloadData" />
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import 'jquery-ui';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import UbahKategori from "@/dialog/UbahKategori";
import FormClosingClaimCourier from "@/dialog/FormClosingClaimCourier";
import moment from "moment";

export default {
  name: "Claim Courier",
  data() {
    return {
        dateStart: moment().add(-3, 'days').format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        method: "",
        roles: "",
        allTicket: {},
        downloading: false,
        show: false,
        filter: {},
        form: {
        status: "",
        },
        dt1: moment().add(-3, 'days'),
        dt2: moment(),
        data_type: "createdOn",
        ticket_type: "",
        selectedItems: [],
        awalItems: '-',
        openClaim: false,
        showClosingClaimCourier: false,
        trackingStatus: 4,
        loading: false,
    };
  },
  watch:{
    $route (to, from){
      if(to.path === "/settings/tickets/claimcourier")
      {
        // sessionStorage.filterData = '';
        // sessionStorage.filterStatus = '';
        // this.filter = {};
        sessionStorage.clear();
		this.filter.dt1 = this.dateStart;
        this.filter.dt2 = this.dateEnd;
		
        this.table.api().ajax.reload();
        this.loadAllStatus(this.filter);
      }
    }
  },
  components: {
    UbahKategori,
    FormClosingClaimCourier,
  },
  created: function () {
    // console.log("filter: ",this.$route.params);
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
      this.loadAllStatus(this.filter);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
        this.filter.claim = "";
        this.loadAllStatus(this.filter);
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
    }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

    if (this.filter.pending > 0) {
        this.filter.claim = 'pending';
    } else if (this.filter.approve > 0) {
        this.filter.claim = 'approve';
    } else if (this.filter.not_approve > 0) {
        this.filter.claim = 'not_approve';
    } else {
        this.ticket_type = '';
    }

    if (this.filter.pending_courier > 0) {
        this.filter.claim = 'pending_courier';
    } else if (this.filter.approve_courier > 0) {
        this.filter.claim = 'approve_courier';
    } else if (this.filter.not_approve_courier > 0) {
        this.filter.claim = 'not_approve_courier';
    } else if (this.filter.banding > 0) {
        this.filter.claim = 'banding';
    } else {
        this.ticket_type = '';
    }

    // this.filter.ticket = this.filter.open > 0 ? this.ticket_type == "open" : this.ticket_type == "closed";
  },
  methods: {
    reloadData: function (e) {
      this.table.api().ajax.reload(null, false);
    },
    selectAll: function (e) {
      var val = e.target.checked;
      var checkboxes = document.querySelectorAll('.select-order');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = val;
      }
      if (val && checkboxes.length > 0)
        this.selectedCount = checkboxes.length;
      else this.selectedCount = 0;
      if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
      else $(this.$refs.btnSelected).hide();
    },
    closeClaimReturn: function () {
      var checkboxes = document.querySelectorAll('.select-order');
      var selected = [];
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          var exp = checkboxes[i].value.split(';');
          selected.push(exp[0]);
        }
      }
      this.selectedItems = selected;
      this.showClosingClaimCourier = !this.showClosingClaimCourier;
    },
    claimReturn: function () {
      var checkboxes = document.querySelectorAll('.select-order');
      var selected = [];
      this.awalItems = '-';
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          var exp = checkboxes[i].value.split(';');
          selected.push(exp[0]);
          if (this.awalItems == '-' && i < 2) {
            this.awalItems = exp[1];
          } else if (exp[1] != this.awalItems) {
            this.awalItems = '-';
          }
        }
      }
      this.selectedItems = selected;
      this.openClaim = !this.openClaim;
    },
    onDate() {
      this.table.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
      // this.table.api().ajax.reload();
    },
    claimTypeCourier(event) {
      sessionStorage.clear();
      let self = this;
      $('#claim option').prop('selected', function() {
        return this.defaultSelected;
      });
      self.filter = {};
      self.claim_type = event.target.value;
      self.filter.claim = self.claim_type;
      self.filter.dt1 = self.dateStart;
      self.filter.dt2 = self.dateEnd;
      self.filter.datatype = self.data_type;
      self.onDate();
      self.loadAllStatus(self.filter);
      // self.table.api().ajax.reload();
    },
    // claimType(event) {
    //   sessionStorage.clear();
    //   let self = this;
    //   $('#claim_courier option').prop('selected', function() {
    //     return this.defaultSelected;
    //   });
    //   self.filter = {};
    //   self.claim_type = event.target.value;
    //   self.filter.claim = self.claim_type;
    //   self.filter.dt1 = self.dateStart;
    //   self.filter.dt2 = self.dateEnd;
    //   self.filter.datatype = self.data_type;
    //   console.log(self.filter.claim);
    //   self.onDate();
    //   self.loadAllStatus(self.filter);
    //   // self.table.api().ajax.reload();
    // },
    loadAllStatus(params) {
      // console.log("params ", Object.keys(params));
      this.loading = true;
      var data = '';
      if (!params) {
        data = '';
      } else {
        data = Object.keys(params)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
          )
          .join("&");
        // console.log(data);
      }

      let url = '';
      // let url = '';
      if (params) {
        url = "/ticket/claim/all_tickets_claims?" + data;
      } else {
        url = "/ticket/claim/all_tickets_claims";
      }

        authFetch(url)
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.allTicket = js.data;
          this.loading = false;
          console.log(js.data);
        });
    },
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = JSON.parse(JSON.stringify(this.filter));
      console.log(filter)
      var data = Object.keys(filter)
        .map(
          (key) =>
          encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
        )
        .join("&");
      console.log(data)
      authFetch("/ticket/claim/download", {
          method: "POST",
          body: data,
        })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "claim.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    handleClick(e) {
      if (e.target.matches("input[type=checkbox]")) {
        var checkboxes = document.querySelectorAll('.select-order:checked');
        if (checkboxes.length == 0) {
          this.$refs.checkall.checked = false;
        } else {
          this.$refs.checkall.checked = true;
        }
        this.selectedCount = checkboxes.length;
        if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
        else $(this.$refs.btnSelected).hide();
        return false;
      }
      if (e.target.matches(".link-order")) {
          let route = this.$router.resolve({
                path: "/order/detail/" + e.target.dataset.id
            });
            window.open(route.href,"_blank");
        // return false;
      }
      if(e.target.closest('.btn-comment')) {
        //this.isShowTicket = !this.isShowTicket;
        var el = e.target.closest('.btn-comment');
        console.log(el)
        this.$root.$emit("openTicketComment", el.dataset.id);
        return false;
      }
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "change") {
          this.form.status = e.target.dataset.status;
          Swal.fire({
            title:
              e.target.dataset.status == 3
                ? "Pending?"
                : e.target.dataset.status == 2
                ? "Closed?"
                : "Open?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              var data = Object.keys(this.form)
                .map(
                  (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(this.form[key])
                )
                .join("&");

              authFetch("/ticket/history/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.filter.dt1 = this.dateStart;
        			this.filter.dt2 = this.dateEnd;

                    this.table.api().ajax.reload();
                    this.loadAllStatus(this.filter);
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.table.api().ajax.reload();
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    this.$root.$on('reloadDashboardTicket', () => {
        this.loadAllStatus();
        this.table.api().ajax.reload( null, false ); // user paging is not reset on reload
    })
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: !this.dt1 ? moment().day(-31) : this.dt1,
        endDate: !this.dt2 ? moment() : this.dt2,
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.filter.datatype = self.data_type;
        // self.filter.ticket = self.ticket_type;
        // self.filter.claim = self.claim_type;
        self.filter.dt1 = self.dateStart;
        self.filter.dt2 = self.dateEnd;
        self.onDate();
        var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, claim: self.filter.claim };
        self.loadAllStatus(json);
      }
    );
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    var info = auth.user();
    this.table = createTable(e.tblticket, {
      title: "List Ticket",
      roles: this.$route.params.roles,
      ajax: "/ticket/claim/ticket_claim",
      frame: false,
      scrollX: true,
      autoWidth:false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
      selectedRowClass: "",
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      filterBy: [1, 2, 3],
      stateSave: true,
      paramData: function (d) {
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0: filter[key];
        }
      },
      "order": [
        [7, 'desc']
      ],
      columns: [
        {
          sortable: false,
          "data": "checkall", // can be null or undefined
          "defaultContent": '<input type="checkbox">',
          render: function (data, type, row, meta) {
            // console.log(info);
            if (info.role_id < 11) {
              return (
                '<input type="checkbox" class="select-order" value="' + row.id + ';' + row.categoryName + '" />'
              );
            } else {
              return ('');
            }
          },
        },
        {
          data: "no_resi",
          render: function (data, type, row, meta) {
            return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${data}</span>`;
          },
        },
        { data: "title" },
        { data: "sender_name" },
        {
          data: "categoryName",
          // render: function (data, type, row, meta) {
          //   var cls = 'secondary';
          //   if(data=='High')cls='danger';
          //   else if(data=='Urgent')cls='warning';
          //   return (
          //     "<span class='badge badge-"+cls+"'>" + row.categoryName + "</span>"
          //   );
          // },
        },
        {
          data: "return_claim_courier",
          render: function (data, type, row, meta) {
            var status = "";
            if (data == 1)
              status += '<span class="badge badge-info">Pending</span>';
            else if (data == 2)
              status += '<span class="badge badge-success">Approved</span>';
            else if (data == 3)
              status += '<span class="badge badge-warning">Not Approved</span>';
            
            if(row.status_claim == 4)
              status += '<span class="badge badge-warning">Banding</span>';

            if(row.trackingStatus == 0)
              if(row.order_type == 1){
                status += '<span class="badge badge-secondary">Menunggu Drop</span>';
              } else {
                status += '<span class="badge badge-secondary">Menunggu Pickup</span>';
              }
            else if (row.trackingStatus == 1)
              status += '<span class="badge badge-warning">Proses</span>';
            else if (row.trackingStatus == 2)
              status += '<span class="badge badge-success">Terkirim</span>';
            else if (row.trackingStatus == 3)
              status += '<span class="badge badge-warning">Proses Retur</span>';
            else if (row.trackingStatus == 4){
              if (row.returnReceived == 0)
                status += '<span class="badge badge-info">Retur Diserahkan</span>';
              else
                status += '<span class="badge badge-danger">Retur Diterima</span>';
            }
            else if(row.trackingStatus == 5)
              status += ' <span class="badge badge-danger">Bermasalah</span>';
            // if (row.return_claim_courier == 2) {
            //   status += ' <span class="badge badge-success">Approved by Courier</span>';
            // } else if (row.return_claim_courier == 3) {
            //   status += ' <span class="badge badge-warning">Not Approved by Courier</span>';
            // }

            return status;
          },
        },
        { data: "pickup_time" },
        { data: "claim_created" },
        // { data: "totalComment", class:'text-center', render: function (data, type, row, meta) {
        //   var clsTxt = '';
        //   var clsIcn = 'far fa-comment';
        //   let tooltipTxt = row.replied ? 'Sudah dibaca, Belum terjawab' : 'Sudah dibaca, Sudah terjawab';

        //   if(row.totalComment>1){
        //       clsIcn = 'far fa-comments';
        //       if (row.replied) {
        //           tooltipTxt = 'Sudah dibaca, Belum terjawab';
        //       }
        //   }
        //   if(row.newUpdate && row.me_last!=1){
        //     tooltipTxt = row.status == 2 ? 'Belum dibaca, Tidak terjawab' : 'Belum dibaca, Belum terjawab';
        //     clsTxt='text-primary';
        //     clsIcn = 'fa fa-comment';
        //     if(row.totalComment>1)clsIcn = 'fa fa-comments';
        //   }
        //   if(row.newUpdate){
        //     //
        //   }
        //   var replied = !row.replied?'':' <i class="fa fa-question"></i> ';
        //   return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+row.ref_code+'" data-toggle="tooltip" data-html="true" title="<h6><strong>'+tooltipTxt+'</strong></h6>"><span class="'+clsTxt+'"><i class="'+clsIcn+'"></i></span> '+data+replied+'</div>');
        // } },
        {
          data: "ref_code",
          render: function (data, type, row, meta) {
            return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+data+'" data-html="true"><span class="text-primary"><i class="fa fa-comment"></i></span></div>');
          }
        },
        {
          data: "aging",
          render: function (data, type, row, meta) {
            if (data) {
              return (data.split(" ")[0]+' Hari');
            } else {
              return '0 Hari';
            }
          }
        },
        // {
        //   data: "ref_code",
        //   sortable:false,
        //   // visible: isShipper ? false : true,
        //   render: function (data, type, row, meta) {
        //     if (row.status_claim == 1) {
        //       return (
        //         `<button type="button" class="ml-2 btn btn-sm btn-danger" @click="claimReturn" :disabled="deleting"><i class="fa fa-exclamation-circle"></i> Claim</button>
        //          <button type="button" class="ml-2 btn btn-sm btn-success" @click="closeClaimReturn" :disabled="deleting">Proses</button>`
        //       );
        //     } else {
        //       return "-";
        //     }
        //   },
        // },
      ],
      rowCallback: function (row, data) {},
      drawCallback: function () {
          $(document).ready(function(){
            $('[data-toggle="tooltip"]').tooltip();   
        });
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      }
    });
  },
};
</script>
<style>
.table-ticket .dataTables_scrollBody{min-height: 100px}
</style>